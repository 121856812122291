import { Box, Flex, keyframes } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useColorModeStuff } from '../ColorMode';

const scaleAnimation = keyframes`
  0% {transform: opacity(0.3)  }
	50% { transform: opacity(1) }
  100% { transform: opacity(0.3) }
`;

const LoadingImage = styled.img`
  animation: ${scaleAnimation} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
`;

export const LoadingScreen = () => {
  const { colorModeSmallLogo } = useColorModeStuff();

  return (
    <Flex h="100vh" w="100vw" justify="center" align="center">
      <Box w="75%" h="75%" maxW="250px" maxH="250px">
        <LoadingImage
          src={colorModeSmallLogo}
          width={'100%'}
          height={'auto'}
          alt=""
        />
      </Box>
    </Flex>
  );
};
