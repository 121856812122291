import Head from 'next/head';

export const Header = () => {
  return (
    <Head>
      <title>TrueBuilt</title>
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};
