import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import { CloseIcon } from '@chakra-ui/icons';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DocumentViewer } from './DocumentViewer/DocumentViewer';
import {
  fileViewerFileState,
  fileViewerLoadingState,
  fileViewerOpenState,
  fileViewerPageState,
} from './atoms';

const PDFViewer = ({
  file,
  pageIndex,
  onClose,
}: {
  file?: string;
  pageIndex?: number;
  onClose: () => void;
}) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const setIsLoading = useSetAtom(fileViewerLoadingState);

  useEffect(() => {
    if (pageIndex) {
      jumpToPage(pageIndex);
    }
  }, [pageIndex]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      {!!file && (
        <Flex
          className="rpv-core__viewer"
          flexDir={'column'}
          h={'100%'}
          w={'100%'}
          border={'1px solid rgba(0, 0, 0, 0.3)'}
        >
          <Flex
            p={2}
            borderBottom={'1px solid rgba(0, 0, 0, 0.1)'}
            alignItems={'center'}
            backgroundColor={'#eeeeee'}
          >
            <Toolbar />
            <Toolbar>
              {(_toolbarSlot) => {
                return (
                  <Flex alignItems={'center'}>
                    <CloseButton variant={'ghost'} onClick={onClose} />
                  </Flex>
                );
              }}
            </Toolbar>
          </Flex>
          <Box flex={1} overflow={'hidden'}>
            <Viewer
              onDocumentLoad={(_e) => {
                setIsLoading(false);
              }}
              fileUrl={file}
              plugins={[toolbarPluginInstance, pageNavigationPluginInstance]}
              initialPage={pageIndex}
              defaultScale={SpecialZoomLevel.PageWidth}
            />
          </Box>
        </Flex>
      )}
    </Worker>
  );
};

// absolute positioned close button in top right corner
const CloseButton = (props: ButtonProps) => {
  return (
    <Button
      rightIcon={<Icon as={CloseIcon} fontSize={12} />}
      color={'brand.darkGray'}
      aria-label="Close document viewer"
      borderRadius={0}
      onClick={props.onClick}
      _hover={{
        bg: 'transparent',
      }}
      {...props}
    >
      Close
    </Button>
  );
};

export const FileViewer = () => {
  const [isOpen, setIsOpen] = useAtom(fileViewerOpenState);
  const file = useAtomValue(fileViewerFileState);
  const page = useAtomValue(fileViewerPageState);
  const setIsLoading = useSetAtom(fileViewerLoadingState);

  const containerRef = React.useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: containerRef,
  });

  const onClose = () => {
    setIsLoading(false);
    setIsOpen(false);
  };

  if (!file) {
    return null;
  }

  return (
    <Flex
      height="100%"
      bg="brand.main"
      position="absolute"
      zIndex={1000}
      top={0}
      right={0}
    >
      {!!isOpen && (
        <Box
          ref={containerRef}
          w={isOpen && file ? '100vw' : '0px'}
          h={'100%'}
          overflowY={'auto'}
        >
          {/* replace dot with empty string if present */}
          {/* {file.extension.replace(/^.*\./, '') !== 'pdf' ? ( */}
          {file.uri.indexOf('pdf') == -1 ? (
            <DocumentViewer
              file={file.uri}
              type={file.extension}
              onClose={onClose}
            />
          ) : (
            <PDFViewer file={file.uri} pageIndex={page} onClose={onClose} />
          )}
        </Box>
      )}
    </Flex>
  );
};
