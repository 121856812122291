import {
  Flex,
  FlexProps,
  IconButton,
  Tooltip,
  useColorMode,
} from '@chakra-ui/react';
import { SubNavLayoutConfig } from '@tb/common';
import { Provider as JotaiProvider, atom } from 'jotai';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, createRef, useCallback, useEffect } from 'react';
import { BsLifePreserver } from 'react-icons/bs';
import { MdSettings } from 'react-icons/md';
import { FileViewer } from '~/components/FileViewer';
import { Header } from '~/components/Header';
import { Notification } from '~/components/Notification/Notification';
import { NovuProvider } from '~/components/NovuProvider';
import { PendoWidget } from '~/components/Pendo';
import { TopBar } from '~/components/TopBar/TopBar';
import { UserMenu } from '~/components/UserMenu';
import { SubNavLayout, SubNavLayoutV2 } from '~/components/common';
import { useChatwoot } from '~/components/common/Chatwoot/useChatwoot';
import { LoadingScreen } from '~/components/common/LoadingScreen';
import { useFeatureFlags } from '~/utils/features/useFeatureFlags';
import EnvironmentLabelBar from '../EnvironmentLabelBar';
import FeedbackProvider from '../Userback/FeedbackProvider';

export type DefaultLayoutProps = {
  subNavHeading?: ReactNode;
  children: ReactNode;
  session: Session | null;
  useSubNavConfig: () => SubNavLayoutConfig | null;
  paddingTop?: number;
  status: 'unauthenticated' | 'loading' | 'authenticated';
  subPortalNav?: ReactNode;
} & FlexProps;

export const navOpenAtom = atom(true);

const SUPPORTED_ROUTES = ['projects', 'bids', 'templates'];

const DefaultLayoutView = ({
  subNavHeading,
  children,
  session,
  useSubNavConfig = () => null,
  subPortalNav,
  ...flexProps
}: DefaultLayoutProps) => {
  const router = useRouter();
  const settingsActive = router.pathname.includes('settings');

  // only showing updated subnav for projects when estimation is enabled,
  // other pages need to be re-designed first for uptake
  const showSubNavV2 = SUPPORTED_ROUTES.some((route) =>
    router.pathname.includes(route),
  );

  const navRef = createRef<HTMLDivElement>();
  const subNavConfig = useSubNavConfig();
  const { toggleWindow } = useChatwoot({ hide: true });
  const { isLoading, isEnabled } = useFeatureFlags();
  const { colorMode } = useColorMode();

  const getNav = useCallback(() => {
    if (subPortalNav) {
      return subPortalNav;
    } else {
      return (
        <TopBar
          bidManagementEnabled={!isLoading && isEnabled('bid-management')}
          estimationEnabled={!isLoading && isEnabled('estimation')}
        />
      );
    }
  }, [isEnabled, isLoading, subPortalNav]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <JotaiProvider>
      <Header />
      <FileViewer />
      <FeedbackProvider>
        <Flex
          bg={colorMode == 'light' ? 'brand.main' : 'gray.800'}
          direction="column"
          justifyContent="stretch"
          height="100vh"
        >
          {/* Head navbar */}
          <Flex
            flexDirection={'column'}
            ref={navRef}
            w={'100%'}
            zIndex={999}
            cursor={'default'}
            alignItems={'center'}
            shadow={'base'}
          >
            {/* Top navbar */}
            <Flex
              justifyContent={'space-between'}
              bg={colorMode == 'light' ? 'brand.white' : 'gray.900'}
              as="nav"
              px="60px"
              w="100%"
            >
              {/* Show navbar menus */}
              {getNav()}

              {/* Right side buttons */}
              <Flex alignItems={'center'}>
                {/* Notifications */}
                {!!session && !!session.user && (
                  <NovuProvider user={session.user}>
                    <Notification />
                  </NovuProvider>
                )}

                {/* Settings */}
                <Link href={'/settings'} passHref legacyBehavior>
                  <IconButton
                    as={'a'}
                    variant={'nav-icon'}
                    aria-label="Settings"
                    icon={<MdSettings size="25px" />}
                    isActive={settingsActive}
                  />
                </Link>

                {/* Contact us */}
                <Tooltip label="Contact Us">
                  <IconButton
                    variant={'nav-icon'}
                    aria-label="start chat"
                    icon={<BsLifePreserver size={20} />}
                    onClick={() => {
                      toggleWindow();
                    }}
                  />
                </Tooltip>

                {/* User menu */}
                {!!session && !!session.user && (
                  <UserMenu
                    firstName={session.user.name || session.user.email || ''}
                    lastName={''}
                  />
                )}
              </Flex>
            </Flex>

            {/* Sub nav */}
            {!!subNavConfig && !showSubNavV2 && (
              <SubNavLayout {...subNavConfig} />
            )}

            {!!subNavConfig && !!showSubNavV2 && (
              <SubNavLayoutV2 {...subNavConfig} heading={subNavHeading} />
            )}
          </Flex>

          {/* Main content */}
          <Flex
            flex={1}
            as="main"
            direction={'column'}
            position={'relative'}
            overflow="scroll"
            {...flexProps}
          >
            {children}
          </Flex>
        </Flex>

        {/* Pendo Widget */}
        {!!session && !!session.user && <PendoWidget user={session.user} />}

        {/* Environment label bar */}
        <EnvironmentLabelBar />
      </FeedbackProvider>
    </JotaiProvider>
  );
};

const DefaultLayout = ({
  subNavHeading,
  children,
  useSubNavConfig,
  paddingTop = 7,
  subPortalNav,
  ...flexProps
}: {
  subNavHeading?: ReactNode;
  children: ReactNode;
  useSubNavConfig?: () => SubNavLayoutConfig | null;
  paddingTop?: number;
  subPortalNav?: ReactNode;
} & FlexProps) => {
  const { data: session, status } = useSession({ required: true });
  const isSub = session?.user?.type === 'SUB';

  const router = useRouter();

  // Move subs back to where they should be if they stray :)
  useEffect(() => {
    if (isSub) {
      router.push('/bid');
    }
  }, [router, isSub]);

  const nullFn = () => null;

  if (status === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <DefaultLayoutView
      session={session}
      useSubNavConfig={useSubNavConfig || nullFn}
      px={'60px'}
      paddingTop={paddingTop}
      status={status}
      subNavHeading={subNavHeading}
      subPortalNav={subPortalNav}
      {...flexProps}
    >
      {children}
    </DefaultLayoutView>
  );
};

export default DefaultLayout;
