import { NovuProvider as NovuAppProvider } from '@novu/notification-center';
import { VerifiedUser } from '@tb/common';
import { ReactNode } from 'react';

const vertical = 'linear-gradient(0deg,#12B856 0%,#2A7347 100%)';

export const NovuProvider = ({
  children,
  user,
}: {
  children: ReactNode;
  user: VerifiedUser;
}) => {
  return (
    <NovuAppProvider
      subscriberId={user.email}
      applicationIdentifier={process.env.NEXT_PUBLIC_NOVU_APP_ID || ''}
      styles={{
        bellButton: {
          dot: {
            rect: {
              fill: '#12B856',
            },
          },
        },
        unseenBadge: {
          root: {
            background: vertical,
          },
        },
        footer: {
          root: {
            display: 'none',
          },
        },
        notifications: {
          listItem: {
            title: {
              '& > span': {
                color: 'var(--chakra-colors-brand-darkGray)',
                fontSize: '14px',
              },
            },
            unread: {
              fontWeight: 'normal',
              '&:before': {
                background: vertical,
              },
            },
          },
        },
        layout: {
          root: {
            paddingTop: '0',
          },
        },
        loader: {
          root: {
            stroke: '#12B856',
          },
        },
        switch: {
          track: {
            'input:checked + &': {
              background: vertical,
            },
          },
        },
      }}
    >
      {children}
    </NovuAppProvider>
  );
};
