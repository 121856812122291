import { tbError } from '@tb/common';
import { useSession } from 'next-auth/react';
import { useEffect, useRef, useState } from 'react';

type UserInput = {
  id: string;
} & ChatwootUser;

function useDelayedInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function useExponentialBackoffComponent(
  callback: () => boolean,
  limit: number,
  onLimitReached: () => void,
) {
  const [attempt, setAttempt] = useState(0);
  const [stop, setStop] = useState(false);
  const delay = Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000);

  const wrappedCallback = () => {
    if (callback()) {
      setStop(true);
    } else {
      if (attempt === limit) {
        onLimitReached();
        setStop(true);
      } else {
        setAttempt((prev) => prev + 1);
      }
    }
  };
  useDelayedInterval(wrappedCallback, stop ? null : delay);
}

export const useChatwoot = ({ hide: shouldHide }: { hide: boolean }) => {
  const toggleVisibility = (display: 'show' | 'hide') => {
    window.$chatwoot.toggleBubbleVisibility(display);
  };

  const toggleWindow = () => {
    window.$chatwoot.toggle();
  };

  const { status, data } = useSession();
  const [loaded, setLoaded] = useState(false);

  const limit = 10;

  const callback = () => {
    if (window?.$chatwoot?.hasLoaded) {
      setLoaded(true);
      return true;
    }
    return false;
  };

  const onLimitReached = () => {
    tbError(`Chatwoot failed to load after ${limit} attempts.`);
  };
  useExponentialBackoffComponent(callback, limit, onLimitReached);

  const setUser = (data: UserInput) => {
    const { id, ...rest } = data;
    window.$chatwoot.setUser(id, rest);
  };

  useEffect(() => {
    const setChatwootUser = () => {
      if (status === 'authenticated') {
        const { user } = data;
        if (user && user.id) {
          const { id, name, email, image } = user;
          setUser({
            id,
            name: name || undefined,
            email,
            avatar_url: image || undefined,
          });
        }
      }
    };

    if (loaded === false) {
      return;
    }

    setChatwootUser();

    if (shouldHide) {
      toggleVisibility('hide');
    } else {
      toggleVisibility('show');
    }
  }, [status, data, loaded, shouldHide]);

  return {
    toggleVisibility,
    toggleWindow,
    setUser,
  };
};

type ChatwootUser = {
  email?: string;
  name?: string;
  avatar_url?: string;
  phone_number?: string;
};

declare global {
  interface Window {
    $chatwoot: {
      toggleBubbleVisibility: (display: 'show' | 'hide') => void;
      setUser: (id: string, data: ChatwootUser) => void;
      hasLoaded: boolean;
      toggle: () => void;
    };
  }
}
