import { Flex, IconButton } from '@chakra-ui/react';
import { IMainState } from '@cyntler/react-doc-viewer/dist/esm/store/mainStateReducer';

import { MdDownload } from 'react-icons/md';

export const HeaderComponent = ({
  headerState,
}: {
  headerState: IMainState;
}) => {
  const { currentDocument } = headerState;

  return (
    <Flex justify={'end'}>
      <IconButton
        className="rpc-core__minimal-button"
        icon={<MdDownload />}
        aria-label="download"
        variant="ghost"
        as="a"
        href={currentDocument?.uri}
      />
    </Flex>
  );
};
