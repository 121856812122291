/**
 * Get the deployed url for the current environment.
 */
export function getDeployedUrl() {
  if (process.env.VERCEL == '1') {
    // deployed url - includes protocol
    if (process.env.NEXT_PUBLIC_BASE_URL) {
      return `${process.env.NEXT_PUBLIC_BASE_URL}`;
    }

    // reference for preview environments on vercel
    if (process.env.VERCEL_URL) {
      return `https://${process.env.VERCEL_URL}`;
    }
  }

  // default to localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

/**
 * Get the download link for a given document sharing id.
 * @param id The document sharing id
 */
export function getDocumentSharingDownloadLink(documentSharingId: string) {
  return `${getDeployedUrl()}/api/links/${documentSharingId}`;
}

/**
 * Safely gets the current environment using the URL
 */
export function getCurrentEnvironment() {
  // Safely get the subdomain name
  const subdomainName = window?.location?.hostname?.split('.')?.[0];

  // Set the text based on the subdomain
  switch (subdomainName) {
    case 'localhost':
      return 'localhost';

    case 'staging':
      return 'staging';

    case 'demo':
      return 'demo';

    default:
      return 'production';
  }
}

/**
 * Get the URL for the segmentation v1 API
 * - production = ai-segmentation-v1
 * - staging = ai-segmentation-v1-staging
 * - demo = ai-segmentation-v1-staging
 * - development = ai-segmentation-v1-dev
 */
export function getSegmentationV1Url() {
  const currentEnvironment = getCurrentEnvironment();

  switch (currentEnvironment) {
    case 'production':
      return 'https://ai-segmentation-v1.truebuilt.app';

    case 'staging':
      return 'https://ai-segmentation-v1-staging.truebuilt.app';

    case 'demo':
      return 'https://ai-segmentation-v1-staging.truebuilt.app';

    default:
      return 'https://ai-segmentation-v1-dev.truebuilt.app';
  }
}

/**
 * Get the URL for the segmentation v2 API
 * - production = ai-segmentation-v2
 * - staging = ai-segmentation-v2-staging
 * - demo = ai-segmentation-v2-staging
 * - development = ai-segmentation-v2-dev
 */
export function getSegmentationV2Url(currentEnvironment: string) {
  switch (currentEnvironment) {
    case 'production':
      return 'https://ai-segmentation-v2.truebuilt.app';

    case 'staging':
      return 'https://ai-segmentation-v2-staging.truebuilt.app';

    case 'demo':
      return 'https://ai-segmentation-v2-staging.truebuilt.app';

    default:
      return 'https://ai-segmentation-v2-dev.truebuilt.app';
  }
}
