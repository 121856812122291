import { Box, CloseButton, Flex } from '@chakra-ui/react';
import { HeaderComponent } from './components/HeaderComponent/HeaderComponent';
import { NoRenderer } from './components/NoRenderer/NoRenderer';
import { GoogleRenderer } from './renderers/GoogleRenderer';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

interface Props {
  file: string;
  type: string;
  onClose?: () => void;
}

const View = ({ file, onClose }: Props) => {
  return (
    <DocViewer
      documents={[
        {
          uri: file,
          fileType: 'jpg',
        },
      ]}
      config={{
        header: {
          overrideComponent: (state) => <HeaderComponent headerState={state} />,
        },
        noRenderer: {
          overrideComponent: (state) => (
            <NoRenderer document={state.document} onClose={onClose} />
          ),
        },
      }}
      pluginRenderers={[...DocViewerRenderers, GoogleRenderer]}
    />
  );
};

export const DocumentViewer = ({ file, type, onClose }: Props) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return (
    <Flex
      className="rpv-core__viewer"
      flexDir={'column'}
      h={'100%'}
      w={'100%'}
      border={'1px solid rgba(0, 0, 0, 0.3)'}
    >
      <Flex
        p={2}
        borderBottom={'1px solid rgba(0, 0, 0, 0.1)'}
        alignItems={'center'}
        backgroundColor={'#eeeeee'}
      >
        <Toolbar />
        <Toolbar>
          {(_toolbarSlot) => {
            return (
              <Flex alignItems={'center'}>
                <CloseButton variant={'ghost'} onClick={onClose} />
              </Flex>
            );
          }}
        </Toolbar>
      </Flex>
      <Box flex={1} overflow={'hidden'}>
        <View file={file} type={type} onClose={onClose} />
      </Box>
    </Flex>
  );
};
