import { VerifiedGC } from '@tb/common';
import { useSession } from 'next-auth/react';
import { getCurrentEnvironment } from '~/utils/url';

const EnvironmentLabelBar = () => {
  let text = '';
  let bgClass = '';

  const auth = useSession();
  const orgCompanyName =
    (auth.data?.user as VerifiedGC)?.organization?.name ?? '';

  const environment = getCurrentEnvironment();

  // Set the text based on the subdomain
  switch (environment) {
    case 'localhost':
      text = 'Development - ' + orgCompanyName;
      bgClass = 'localhost';
      break;

    case 'staging':
      text = 'Staging - ' + orgCompanyName;
      bgClass = 'staging';
      break;

    default:
      text = orgCompanyName;
      bgClass = 'default';
  }

  return (
    <a
      href="https://truebuiltsoftware.com"
      className={`${bgClass}-env-bar-bg`}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );
};

export default EnvironmentLabelBar;
