import { Box, Button, ButtonProps, Flex } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';
import { useColorModeStuff } from '../common';

const TopMenuContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      direction={'row'}
      // alignItems={isExpanded ? 'flex-start' : 'center'}
      gap={5}
      // pt={isExpanded ? '72px' : '112px'}
      w={'100%'}
    >
      {children}
    </Flex>
  );
};

const StyledNavButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      // isExpanded,
      ...rest
    },
    ref,
  ) => {
    return (
      <Button {...rest} variant={'nav'} w="100%" fontSize="md" ref={ref}>
        {children}
      </Button>
    );
  },
);

StyledNavButton.displayName = 'StyledNavButton';

export const TopBar = ({
  estimationEnabled,
  bidManagementEnabled,
}: {
  estimationEnabled: boolean;
  bidManagementEnabled: boolean;
}) => {
  const router = useRouter();
  const { colorModeGreen, colorModeSmallLogo } = useColorModeStuff();

  const selections = [
    {
      header: 'Home',
      route: '/',
      testId: 'nav-home',
    },
    {
      header: 'Projects',
      route: '/projects',
      testId: 'nav-projects',
    },
  ];

  if (bidManagementEnabled) {
    const templates = {
      header: 'Templates',
      route: '/templates',
      testId: 'nav-templates',
    };

    const subcontractors = {
      header: 'Subcontractors',
      route: '/subcontractors',
      testId: 'nav-subcontractors',
    };

    selections.push(templates);
    selections.push(subcontractors);
  }

  if (estimationEnabled) {
    const catalog = {
      header: 'Database',
      route: '/database/items',
      testId: 'nav-master',
    };

    selections.push(catalog);
  }

  return (
    <Box>
      <TopMenuContainer data-testid="topbar-container">
        <Box
          mr={6}
          alignSelf={'center'}
          width={'39px'}
          height={'24px'}
          cursor="pointer"
        >
          <Link href="/" passHref={true}>
            <Image
              src={colorModeSmallLogo}
              alt="logo"
              width={311}
              height={180}
            />
          </Link>
        </Box>
        {selections.map(({ header, route, testId }) => {
          const isActive =
            route !== '/'
              ? router.pathname.includes(route)
              : router.pathname === route;
          return (
            <Box key={route}>
              <Link href={route} passHref legacyBehavior>
                <StyledNavButton
                  as={'a'}
                  isActive={isActive}
                  fontWeight={isActive ? 'bold' : 'normal'}
                  data-testid={testId}
                  _active={{
                    color: colorModeGreen,
                  }}
                  _hover={{
                    color: colorModeGreen,
                  }}
                >
                  {header}
                </StyledNavButton>
              </Link>
            </Box>
          );
        })}
      </TopMenuContainer>
    </Box>
  );
};
