import { atom } from 'jotai';

export const fileViewerOpenState = atom<boolean>(false);
export const fileViewerPageState = atom<number | undefined>(undefined);
export const fileViewerLoadingState = atom<boolean>(false);

export type FileViewerFile = {
  uri: string;
  extension: string;
  id: string;
};

export const fileViewerFileState = atom<FileViewerFile | undefined>(undefined);
