import Script from 'next/script';
import { VerifiedUser } from '@tb/common';
import { trpc } from '~/utils/trpc';
const BASE_URL = 'https://cdn.pendo.io/agent/static';

type Visitor = {
  // Required if user is logged in, default creates anonymous ID
  id: string | number;
  // Recommended if using Pendo Feedback, or NPS Email
  email?: string;
  // Recommended if using Pendo Feedback
  full_name?: string;
  // Optional
  role?: string;
};

type Account = {
  // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
  id: string | number;
  // Optional
  name?: string;
  // Recommended if using Pendo Feedback
  is_paying?: boolean;
  // Recommended if using Pendo Feedback
  monthly_value?: string;
  // Optional
  planLevel?: string;
  // Optional
  planPrice?: string;
  // Optional
  creationDate?: number;
};

type PendoOpts = {
  visitor: Visitor;
  account: Account;
};

declare global {
  interface Window {
    pendo: {
      initialize: (initializer: PendoOpts) => void;
    };
  }
}

// Taken from the Pendo installation guide with minimal modifications
// to make it work for Typescript/React
function initialize(opts: PendoOpts) {
  return (function () {
    (function (p: any, d: any, o: any) {
      let w: any, x: any;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      const v = [
        'initialize',
        'identify',
        'updateOptions',
        'pageLoad',
        'track',
      ];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m: any) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push'](
                // eslint-disable-next-line prefer-rest-params
                [m].concat([].slice.call(arguments, 0)),
              );
            };
        })(v[w]);
    })(window, 'pendo', {});

    window.pendo.initialize(opts);
  })();
}

function userToPendoOpts(user: VerifiedUser): PendoOpts {
  const { id, email, name, role } = user;
  const visitor: Visitor = {
    id,
    email,
    full_name: name || email,
    role: role,
  };

  // Attach Org level for GC users
  if ('organization' in user) {
    const { organization } = user;
    const { id, name } = organization;

    return {
      visitor,
      account: {
        id,
        name,
      },
    };
  }

  // Subs don't have an Org & are just themselves
  return {
    visitor,
    account: {
      id,
      name: name || email,
    },
  };
}

const Pendo = ({ apiKey, opts }: { apiKey: string; opts: PendoOpts }) => {
  const onLoad = () => {
    initialize(opts);
  };

  return (
    <Script async src={`${BASE_URL}/${apiKey}/pendo.js`} onLoad={onLoad} />
  );
};

export const PendoWidget = ({ user }: { user: VerifiedUser }) => {
  const { data, isLoading } = trpc.pendo.getApiKey.useQuery(undefined, {
    refetchOnWindowFocus: false,
    staleTime: 10 * 60 * 1000, // 10 minutes
  });
  if (isLoading || !data) {
    return null;
  }

  const opts = userToPendoOpts(user);

  return <Pendo apiKey={data} opts={opts} />;
};
