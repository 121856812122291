import { BellIcon } from '@chakra-ui/icons';
import {
  Avatar,
  AvatarBadge,
  IconButton,
  IconButtonProps,
  useColorMode,
} from '@chakra-ui/react';

type NotificationButtonProps = {
  unread?: boolean;
  buttonProps?: Omit<IconButtonProps, 'aria-label' | 'onClick'>;
  onClick?: IconButtonProps['onClick'];
};

export const NotificationButton = ({
  unread,
  buttonProps,
}: NotificationButtonProps) => {
  const { colorMode } = useColorMode();
  return (
    <IconButton
      aria-label="notification"
      variant={'nav-icon'}
      icon={
        <Avatar
          bg="transparent"
          color={'brand.darkGray'}
          fontSize={'25px'}
          variant={'nav-icon'}
          icon={<BellIcon />}
          _hover={colorMode === 'dark' ? 'complete.100' : 'complete.500'}
        >
          {!!unread && (
            <AvatarBadge
              placement="top-end"
              transform="translate(20%, 0%)"
              boxSize="1em"
              bg="green.500"
            />
          )}
        </Avatar>
      }
      {...buttonProps}
    />
  );
};
