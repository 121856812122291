import { UserbackProvider } from '@userback/react';
import { useSession } from 'next-auth/react';
import { ReactNode } from 'react';
import { trpc } from '~/utils/trpc';

/**
 * Integrates with Userback for user feedback.
 *
 * @remarks
 * {@link https://docs.userback.io/reference/javascript-api-overview | API Overview}
 */
const FeedbackProvider = ({ children }: { children: ReactNode }) => {
  const { data: session } = useSession();

  const { data: token } = trpc.userback.getToken.useQuery(undefined, {
    refetchOnWindowFocus: false,
    staleTime: 10 * 60 * 1000,
  });

  const ubOpts = {
    name: session?.user?.name || 'anon',
    email: session?.user?.email || 'anon',
  };

  if (token) {
    return (
      <UserbackProvider token={token} options={ubOpts}>
        {children}
      </UserbackProvider>
    );
  }

  return children;
};

export default FeedbackProvider;
