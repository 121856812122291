import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  useColorMode,
} from '@chakra-ui/react';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useFeatureFlags } from '~/utils/features/useFeatureFlags';

export const AvatarIcon = ({ name }: { name: string }) => {
  return <Avatar size="sm" name={name} />;
};

export const UserMenu = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  const router = useRouter();
  const { colorMode, toggleColorMode } = useColorMode();
  const featureFlags = useFeatureFlags();

  const handleToggleColorMode = () => {
    if (window.location.pathname.includes('takeoffs')) {
      localStorage.setItem(
        'chakra-ui-color-mode',
        colorMode === 'light' ? 'dark' : 'light',
      );

      router.reload();
      return;
    }

    toggleColorMode();
  };

  // Makes the logout process look super smooth
  const onSignOut = async () => {
    const data = await signOut({ redirect: false, callbackUrl: '/auth/login' });
    router.push(data.url);
  };

  return (
    <VStack>
      <Menu>
        <MenuButton className="menu-button" pl={2}>
          <AvatarIcon name={`${firstName} ${lastName}`} />
        </MenuButton>
        <MenuList>
          {!featureFlags.isLoading && featureFlags.isEnabled('darkmode') && (
            <MenuItem onClick={() => handleToggleColorMode()}>
              Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
            </MenuItem>
          )}

          <MenuItem onClick={() => onSignOut()}>Sign Out</MenuItem>
        </MenuList>
      </Menu>
    </VStack>
  );
};
