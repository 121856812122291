import { Button, Flex, Text } from '@chakra-ui/react';
import { IDocument } from '@cyntler/react-doc-viewer';
import { BiArrowToBottom as ArrowDownIcon } from 'react-icons/bi';
type Props = {
  document: IDocument | undefined;
  onClose?: () => void;
};

export const NoRenderer = ({ document, onClose }: Props) => {
  return (
    <Flex
      flexDir={'column'}
      width={'100%'}
      height={'100%'}
      justify={'center'}
      align="center"
      gap={4}
    >
      <Flex flexDir={'column'} align="center">
        <Text>Cannot view this file in the browser</Text>
        <Text variant={'body-muted'}>Click to download</Text>
      </Flex>

      <Button
        as="a"
        href={document?.uri}
        rightIcon={<ArrowDownIcon />}
        variant="outline"
      >
        Download
      </Button>
      <Button variant="outline-red" onClick={onClose}>
        Close
      </Button>
    </Flex>
  );
};
