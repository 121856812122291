import { Box, chakra } from '@chakra-ui/react';
import { DocRenderer } from '@cyntler/react-doc-viewer';

const IFrame = chakra('iframe', {
  baseStyle: {
    width: '100%',
    height: '100%',
    border: 0,
  },
});

// Example of how to extend renderers
export const GoogleRenderer: DocRenderer = ({
  mainState: { currentDocument },
}) => {
  if (!currentDocument) return null;

  const formatEmbedUrl = (url: string) => {
    return `https://docs.google.com/viewer?url=${encodeURIComponent(
      url,
    )}&embedded=true`;
  };

  return (
    <Box id="google-renderer" width="100%">
      <IFrame
        id="google-renderer-iframe"
        title="google-renderer-iframe"
        src={formatEmbedUrl(currentDocument.uri)}
      />
    </Box>
  );
};

GoogleRenderer.fileTypes = ['svg'];
GoogleRenderer.weight = 2;
